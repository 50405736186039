import React from 'react';
import './Footer.css';

const Footer = () => (
  <div className="footer">
    <a href="https://www.instagram.com/saltandpinehomes">
      <i class="fab fa-instagram"></i>
    </a>
  </div>
);

export default Footer;