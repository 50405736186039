import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Scroll extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      height: 0
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleScroll();
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const pageOffset = window.pageYOffset;
    const target = document.getElementById( this.props.target ) || this.target;
    this.setState({
      active: pageOffset >= target.offsetTop,
      height: target.offsetHeight
    });
  }

  render() {
    return (
      <div ref={(target) => { this.target = target; }} {...this.props}>
        { this.props.render( { ...this.state } ) }
      </div>
    );
  }

}

export default withRouter(Scroll);
