import React, { Component } from 'react';
import './App.css';
import HomeHeader from './Pages/Home/HomeHeader';
import Home from './Pages/Home/Home';
import ScrollToTop from './Common/ScrollToTop';
import Footer from './Common/Footer/Footer';
import Nav from './Common/Nav/Nav';

import { BrowserRouter as Router, Route } from 'react-router-dom';
class App extends Component {
  render() {
    return (
      <div className="app">
        <div className="content">
          <Router>
            <ScrollToTop>
              <Route path="/" exact component={HomeHeader} />
              <Nav />
              <Route path="/" exact component={Home} />
            </ScrollToTop>
          </Router>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
