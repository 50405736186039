import React from 'react';
import './Logo.css';

const Logo = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="913.454"
  height="198.94"
  viewBox="0 0 913.454 198.94"
  className="logoText"
>
  <g
    id="Group_1"
    data-name="Group 1"
    transform="translate(-343.273 -881.442)"
  >
    <path
      id="Path_1"
      data-name="Path 1"
      d="M373.733,970.719a58.548,58.548,0,0,1-30.46-8.424l2.949-14.036h2.808c3.509,11.51,14.458,14.6,25.267,14.6s21.895-4.911,21.895-16.283c0-9.121-6.6-13.754-23.861-16.843-14.74-2.667-28.075-9.544-28.075-23.862,0-14.458,12.775-24.284,30.742-24.284,15.3,0,24.844,5.052,27.092,6.317l-2.667,13.335h-2.949c-3.791-9.967-13.056-11.933-21.335-11.933-11.372,0-21.476,5.193-21.476,15.3,0,8.282,6.316,12.214,19.929,14.74,19.933,3.65,32.567,10.107,32.567,25.968C406.159,961.312,391.841,970.719,373.733,970.719Z"
    />
    <path
      id="Path_2"
      data-name="Path 2"
      d="M516.5,969.454H496.283l-1.4-5.193c5.894,0,6.6-1.543,4.771-6.038l-6.176-15.3H451.087l-6.317,15.3c-1.966,4.914-.845,6.038,4.77,6.038l-1.4,5.193H429.329l-1.406-5.193c4.214,0,5.475-1.124,7.582-6.038l28.353-66.814c.983-2.385,0-3.23-4.633-3.23l1.406-5.193h24.7l1.406,5.193c-4.915,0-5.616.982-4.633,3.23l28.215,66.814c1.963,4.773,3.65,6.038,7.579,6.038Zm-41.832-73.412A42.724,42.724,0,0,1,472.7,887.9a38.927,38.927,0,0,1-2.1,8.142l-16,38.6h35.512Z"
    />
    <path
      id="Path_3"
      data-name="Path 3"
      d="M603.815,969.454H543.177l-1.405-5.193c4.773,0,6.175-1.825,6.175-6.6V894.777c0-4.911-1.4-6.6-6.175-6.6l1.405-5.193h19.651l1.4,5.193c-4.912,0-6.176,1.687-6.176,6.6v66.958h32.566c7.578,0,8.7-1.966,9.967-8.7l4.77,1.546Z"
    />
    <path
      id="Path_4"
      data-name="Path 4"
      d="M690.011,899.41c-.842-5.894-2.244-8.7-9.966-8.7h-19.37v66.955c0,4.773,1.4,6.6,6.176,6.6l-1.406,5.193H645.794l-1.4-5.193c4.911,0,6.176-1.825,6.176-6.6V890.708H631.2c-7.722,0-9.124,2.808-9.966,8.7l-4.633-1.543,1.543-14.881H693.1l1.543,14.881Z"
    />
    <path
      id="Path_5"
      data-name="Path 5"
      d="M844.99,969.454H833.759a40.234,40.234,0,0,1-10.1-7.3A44,44,0,0,1,797.546,971c-20.074,0-31.865-10.386-31.865-25.968,0-14.036,9.684-23.16,21.9-27.229l-1.546-1.825c-4.911-5.757-7.159-11.792-7.159-16.988,0-9.544,7.863-17.545,20.916-17.545,10.809,0,19.373,4.774,21.758,6.035l-2.667,13.056h-2.948c-1.825-8.141-7.86-11.65-15.3-11.65-8.842,0-12.211,5.193-12.211,10.1s1.825,7.722,6.6,13.757l25.971,32.567c1.4,1.684,2.667,3.368,4.069,4.911a32.249,32.249,0,0,0,6.458-19.51v-1.4c0-4.773-1.825-6.457-8-6.457l1.825-5.193h19.51l1.825,5.193c-6.176,0-7.719,1.684-7.719,6.457v.7c0,10.809-3.371,19.37-8.705,25.828l.564.563c7.017,6.736,8.983,7.86,16,7.86Zm-30.741-17.826-21.9-27.653c-10.668,2.946-17.545,10.105-17.545,19.793,0,12.633,10.248,19.651,23.724,19.651a33.387,33.387,0,0,0,20.071-6.6C817.2,955.277,815.792,953.452,814.249,951.628Z"
    />
    <path
      id="Path_6"
      data-name="Path 6"
      d="M959.12,934.221H940.029v23.442c0,4.773,1.406,6.6,6.176,6.6l-1.4,5.193H925.151l-1.405-5.193c4.914,0,6.176-1.825,6.176-6.6V894.777c0-4.911-1.262-6.6-6.176-6.6l1.405-5.193h32.986c21.336,0,31.863,10.389,31.863,25.407C990,923.835,979.332,934.221,959.12,934.221ZM958,891.268H940.029v34.67h19.232c14.177,0,20.634-6.454,20.634-17.545C979.9,897.867,973.438,891.268,958,891.268Z"
    />
    <path
      id="Path_7"
      data-name="Path 7"
      d="M1040.123,969.454h-19.651l-1.4-5.193c4.914,0,6.179-1.825,6.179-6.6V894.777c0-4.911-1.265-6.6-6.179-6.6l1.4-5.193h19.651l1.4,5.193c-4.771,0-6.176,1.687-6.176,6.6v62.886c0,4.773,1.405,6.6,6.176,6.6Z"
    />
    <path
      id="Path_8"
      data-name="Path 8"
      d="M1153.127,894.777V970.3h-6.176l-52.778-67.377a60.445,60.445,0,0,1-4.214-6.176s.423,4.21.423,6.877v54.043c0,4.773,1.261,6.6,6.035,6.6l-1.4,5.193h-17.968l-1.405-5.193c4.773,0,6.176-1.825,6.176-6.6V894.777c0-4.911-1.4-6.6-6.176-6.6l1.405-5.193h14.036l49.833,63.586c2.1,2.808,4.069,6.179,4.069,6.179s-.419-3.931-.419-7.021V894.777c0-4.911-1.406-6.6-6.18-6.6l1.406-5.193h17.967l1.4,5.193C1154.391,888.179,1153.127,889.866,1153.127,894.777Z"
    />
    <path
      id="Path_9"
      data-name="Path 9"
      d="M1255.183,969.454h-61.9l-1.406-5.193c4.774,0,6.176-1.825,6.176-6.6V894.777c0-4.911-1.4-6.6-6.176-6.6l1.406-5.193h57.551v14.881l-4.632,1.543c0-6.877-1.547-8.7-12.073-8.7h-25.968v29.618h27.373c4.77,0,6.454-1.406,6.454-6.176l4.355,1.4v17.266l-4.355,1.4c0-4.912-1.684-6.176-6.454-6.176h-27.373v33.69h33.827c7.441,0,8.706-1.966,9.967-8.7l4.774,1.546Z"
    />
    <path
      id="Path_10"
      data-name="Path 10"
      d="M631.742,1037.343a1.225,1.225,0,0,1,1.185-1.181h4.108a1.182,1.182,0,0,1,1.184,1.181v17.379h22.8v-17.379a1.182,1.182,0,0,1,1.184-1.181h4.108a1.224,1.224,0,0,1,1.184,1.181v41.23a1.225,1.225,0,0,1-1.184,1.185H662.2a1.183,1.183,0,0,1-1.184-1.185v-18h-22.8v18a1.183,1.183,0,0,1-1.184,1.185h-4.108a1.226,1.226,0,0,1-1.185-1.185Z"
    />
    <path
      id="Path_11"
      data-name="Path 11"
      d="M729.909,1035.538a22.422,22.422,0,1,1-22.359,22.485A22.382,22.382,0,0,1,729.909,1035.538Zm0,38.614a16.192,16.192,0,1,0-16.132-16.129A16.186,16.186,0,0,0,729.909,1074.152Z"
    />
    <path
      id="Path_12"
      data-name="Path 12"
      d="M797.12,1036.473a1.258,1.258,0,0,1,1.124-.935h1a1.271,1.271,0,0,1,1.059.685l13.015,31.827h.25l12.953-31.827a1.194,1.194,0,0,1,1.06-.685h1a1.255,1.255,0,0,1,1.12.935l7.661,41.854a1.061,1.061,0,0,1-1.12,1.431h-4.047a1.29,1.29,0,0,1-1.184-.871l-4.611-27.841h-.185L814.995,1079.7a1.057,1.057,0,0,1-1.057.685h-1.12a1.122,1.122,0,0,1-1.06-.685l-11.273-28.651h-.25l-4.485,27.841a1.208,1.208,0,0,1-1.121.871h-4.046a1.117,1.117,0,0,1-1.185-1.431Z"
    />
    <path
      id="Path_13"
      data-name="Path 13"
      d="M877.662,1037.343a1.183,1.183,0,0,1,1.185-1.181h24.911a1.18,1.18,0,0,1,1.182,1.181v3.49a1.181,1.181,0,0,1-1.182,1.182h-19.68v12.707h16.629a1.224,1.224,0,0,1,1.182,1.181v3.49a1.181,1.181,0,0,1-1.182,1.181H884.078v13.392h19.68a1.181,1.181,0,0,1,1.182,1.182v3.425a1.181,1.181,0,0,1-1.182,1.185H878.847a1.184,1.184,0,0,1-1.185-1.185Z"
    />
    <path
      id="Path_14"
      data-name="Path 14"
      d="M942.693,1073.467c.56-.81,1.056-1.742,1.617-2.552a1.412,1.412,0,0,1,2.119-.5c.372.31,5.168,4.3,9.964,4.3,4.3,0,7.037-2.615,7.037-5.791,0-3.737-3.237-6.1-9.4-8.657-6.352-2.677-11.334-5.978-11.334-13.2,0-4.856,3.736-11.522,13.639-11.522a21.116,21.116,0,0,1,11.522,3.675,1.479,1.479,0,0,1,.372,2.116c-.5.75-1.057,1.62-1.556,2.37a1.474,1.474,0,0,1-2.181.621c-.435-.25-4.8-3.112-8.407-3.112-5.231,0-7.037,3.3-7.037,5.6,0,3.55,2.74,5.73,7.908,7.847,7.226,2.929,13.392,6.355,13.392,13.952,0,6.477-5.792,11.772-13.888,11.772a20.18,20.18,0,0,1-13.393-4.92A1.311,1.311,0,0,1,942.693,1073.467Z"
    />
  </g>
</svg>
);

export default Logo;