import React from 'react';
import Scroll from '../Scroll';
import './Nav.css';

import { Link } from 'react-router-dom';

const NavLink = ({to, children}) => (
  <Link to={to} className="nav-link">{children}</Link>
)

const Nav = () => (
  <Scroll render={({ active }) => (
    <>
      <div className={`nav ${active ? 'fixed' : ''}`}>
        <nav>
        <NavLink to="/" >Home</NavLink>
        <NavLink to="/about" >About</NavLink>
        </nav>
      </div>
      { active ? <div className="nav" /> : null }
    </>
  )} style={{width: '100%'}}/>
);

export default Nav;