import React from 'react';
import LogoMark from '../../Common/Logo/LogoMark';
import Logo from '../../Common/Logo/Logo';

const HomeHeader = () => (
  <header className="home-header">
    <LogoMark />
    <Logo />
  </header>
);

export default HomeHeader;